import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfile } from '../Models';
import { UserService } from '../../admin/_services/user.service';
import { FileResult } from '../../admin/_entities/FileResult';
import { UserSettingService } from '../../admin/_services/usersetting.service';

@Component({
   selector: 'app-user-menu',
   templateUrl: './user-menu.component.html',
   styleUrls: ['./user-menu.component.css']})
export class UserMenuComponent implements OnInit {
   @Input() userProfile: UserProfile;

   userInitials: string;
   avatarUrl?: any;
   loading = false;
   showInitials = false;
   constructor(private router: Router, private userService: UserService, private userSettingService: UserSettingService) {}

   ngOnInit(): void {
      this.userService.getUserDetails(this.userProfile.userIdentifier).subscribe((user) => {
         this.userProfile = user;
      });
      this.userSettingService.getuserProfilePic(this.userProfile.tenantId, this.userProfile.id).subscribe(
         (file: FileResult) => {
            if (file !== null) {
               //const byteData: Uint8Array = file.fileContent;
               const blob = new Blob([file.fileContent], { type: 'image/jpeg' });
               //const blobParts: BlobPart[] = [blob];
               const fileName = file.file.originalFileName + file.file.fileExtention;
               const fileNew = new File([blob], fileName);
               this.getBase64(fileNew, (img: string) => {
                  const imgDataUrl = `data:${file.file.contentType};base64,${file.fileContent}`;
                  this.showInitials = false;
                  this.loading = false;
                  this.avatarUrl = imgDataUrl;
               });
            } else {
               this.showInitials = true;
            }
         },
         (error: any) => {
            console.error(error);
         }
      );
      if (this.userProfile.lastName !== null) {
         this.userInitials = this.userProfile.firstName.charAt(0).toUpperCase() + this.userProfile.lastName.charAt(0).toUpperCase();
      } else {
         this.userInitials = this.userProfile.firstName.charAt(0).toUpperCase();
      }
   }

   private getBase64(img: File, callback: (img: string) => void): void {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result!.toString()));
      reader.readAsDataURL(img);
   }
   onLogout() {
      this.router.navigate(['/authentication/logout']);
   }

   viewUserSetting(id) {
      this.router.navigate(['/admin/consumer/usersetting', id]);
   }
}
