<router-outlet></router-outlet>
<nz-modal [(nzVisible)]="idleVisible" nzTitle="Session Expired" nzFooter=" " nzClosable="false">
   <p>
      Session expired! Please click OK to log in again!
   </p>
   <div nz-row [nzGutter]="16">
      <div nz-col [nzSpan]="24">
         <button style="float: inline-end;" type="submit" nz-button class="btn btn-blue Single-button" nzShape="round" (click)="accept()">
            OK
         </button>
      </div>
   </div>
</nz-modal>
