<div class="context-area" #contextMenu *ngIf="isVisible"
    [ngStyle]="{ top: position.y + 'px', left: position.x + 'px' }">
    <div class="content">
        <span #stageElement (mouseover)="hoverElement(1, $event, stageElement.clientWidth)">
            Logics <span nz-icon nzType="right" nzTheme="outline"></span>
        </span>
        <span #stageElement (mouseover)="hoverElement(2, $event, stageElement.clientWidth)">
            Field Types <span nz-icon nzType="right" nzTheme="outline"></span>
        </span>
        <span #stageElement (mouseover)="hoverElement(3, $event, stageElement.clientWidth)">
            Functions <span nz-icon nzType="right" nzTheme="outline"></span>
        </span>
    </div>
</div>

<div class="context-area" #contextMenuLayer2 *ngIf="step2Selection !== 0"
    [ngStyle]="{ top: positionLayer2.y + 'px', left: positionLayer2.x + 'px' }">
    <div class="contentLayer2">
        <div *ngIf="step2Selection == 1">
            <span (click)="triggerLogics()">Open Logics</span>
        </div>
        <div *ngIf="step2Selection == 2">
            <span *ngFor="let dt of contextMenuItems" (click)="typeChanges(dt.id)">{{dt.description}}</span>
        </div>
        <div *ngIf="step2Selection == 3">
            <span (click)="triggerFunctions()">Open Functions</span>
        </div>
    </div>
</div>