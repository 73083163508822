import { Component, ElementRef, HostListener, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit {
  @ViewChild('contextMenu') menuContainer!: ElementRef;
  @ViewChild('contextMenuLayer2') menuContainerLayer2!: ElementRef;
  @Input() data: any = {};
  @Input() contextMenuItems: any = [];
  @Output() triggerLogic: EventEmitter<any> = new EventEmitter<any>();
  @Output() triggerFunction: EventEmitter<any> = new EventEmitter<any>();
  @Output() typeChange: EventEmitter<any> = new EventEmitter<any>();
  isVisible = false;
  position = { x: 0, y: 0 };
  positionLayer2 = { x: 0, y: 0 };
  step2Selection: number = 0;
  

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.isVisible && !this.elementRef.nativeElement.contains(event.target)) {
      this.closeMenu();
    }
  }

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void { }

  toggleItem(item: any): void {
    item.expanded = !item.expanded;
  }

  openMenu(x: number, y: number, data: any) {
    this.isVisible = true;
    this.data = data;

    setTimeout(() => {
      if (this.menuContainer && this.menuContainer.nativeElement) {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        const menuWidth = 192; // this.menuContainer.nativeElement.offsetWidth
        const menuHeight = 80; // this.menuContainer.nativeElement.offsetHeight

        if (x + menuWidth > viewportWidth) {
          x = viewportWidth - menuWidth;
        }
        if (y + menuHeight > viewportHeight) {
          y = viewportHeight - menuHeight;
        }

        this.position = { x, y };
      }
      else {
        this.position = { x, y };
      }
    });
  }

  openMenuLayer2(x: number, y: number) {
    setTimeout(() => {
      if (this.menuContainer && this.menuContainer.nativeElement) {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        let menuWidth = 192; // this.menuContainer.nativeElement.offsetWidth
        let menuHeight = 0; // this.menuContainer.nativeElement.offsetHeight

        if (this.step2Selection === 1) {
          menuHeight = 44;
        } else if (this.step2Selection === 2) {
          menuHeight = 332;
        }

        if (x + menuWidth > viewportWidth) {
          x = viewportWidth - menuWidth;
        }
        if (y + menuHeight > viewportHeight) {
          y = viewportHeight - menuHeight;
        }

        this.positionLayer2 = { x, y };
      }
      else {
        this.positionLayer2 = { x, y };
      }
    });
  }

  hoverElement(item: any, event: MouseEvent, clientWidth: any) {
    this.step2Selection = item;
    let eTarget: any = event.target;
    let x = this.position.x + clientWidth;
    let y = this.position.y + eTarget['offsetTop'];

    setTimeout(() => {
      if (this.menuContainerLayer2 && this.menuContainerLayer2.nativeElement) {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        let menuWidth = 192; // this.menuContainer.nativeElement.offsetWidth
        let menuHeight = 0; // this.menuContainer.nativeElement.offsetHeight

        if (this.step2Selection === 1) {
          menuHeight = 44;
        } else if (this.step2Selection === 2) {
          menuHeight = 332;
        }

        if (x + menuWidth > viewportWidth) {
          x = viewportWidth - menuWidth;
        }
        if (y + menuHeight > viewportHeight) {
          y = viewportHeight - menuHeight;
        }

        this.positionLayer2 = { x, y };
      }
      else {
        this.positionLayer2 = { x, y };
      }
    });
  }

  calculatePosition(x: number, y: number, container: ElementRef): { x: number; y: number } {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const menuWidth = container.nativeElement.offsetWidth;
    const menuHeight = container.nativeElement.offsetHeight;

    if (x + menuWidth > viewportWidth) {
      x = viewportWidth - menuWidth;
    }
    if (y + menuHeight > viewportHeight) {
      y = viewportHeight - menuHeight;
    }

    return { x, y };
  }

  closeMenu(): void {
    this.isVisible = false;
    this.step2Selection = 0;
  }

  triggerLogics(): void {
    this.triggerLogic.emit(this.data);
  }

  triggerFunctions(): void {
    this.triggerFunction.emit(this.data);
  }

  typeChanges(id: any): void {
    this.data.informationResponseTypeId = id;
    this.typeChange.emit(this.data);
  }
}
