import { Component, OnInit } from '@angular/core';
import { AuthorizeService } from '../api-authorization/authorize.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { ApplicationPaths } from '../api-authorization/api-authorization.constants';
import { Router } from '@angular/router';

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
   title = 'app';
   idleVisible = false;
   isLogin = false;

   constructor(private bnIdle: BnNgIdleService, private authorizeService: AuthorizeService, private router: Router) {}

   ngOnInit(): void {
      // 30 minutes to time out
      this.bnIdle.startWatching(1800).subscribe((res) => {
         if (this.router.url == '/' + ApplicationPaths.LoggedOut) {
            this.isLogin = true;
         }
         if (res && !this.isLogin) {
            this.idleVisible = true;
         }
      });
   }

   accept() {
      this.authorizeService.autoLogout();
      this.idleVisible = false;
   }
}
