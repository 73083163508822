<button nz-button class="btn btn-blue Single-button mb-2 mr-2 mt-3" nzShape="round" (click)="openModel()">
   <i nzType="camera" nzTheme="outline"></i> Open Camera
</button>

<nz-modal [nzVisible]="isOpenModel" [nzTitle]="null" [nzContent]="modalContent" [nzFooter]="null" (nzOnCancel)="handleCloseModel()">
   <ng-template #modalTitle>Custom Modal Title</ng-template>

   <ng-template #modalContent>
      <div class="video-container" *ngIf="error.length === 0; else noError">
         <video
            [class.show]="captureStatus === 20"
            [class.hide]="captureStatus !== 20"
            #video
            id="video"
            [width]="cameraRatio.width"
            [height]="cameraRatio.height"
            class="w-full"
            autoplay
         ></video>
         <canvas
            [class.show]="captureStatus === 30"
            [class.hide]="captureStatus !== 30"
            #canvas
            id="canvas"
            [width]="cameraRatio.width"
            [height]="cameraRatio.height"
            class="w-full"
         ></canvas>
      </div>
      <ng-template #noError>
         <pre class="errorText" *ngFor="let err of error">{{ err }}</pre>
      </ng-template>

      <div class="snap-container">
         <div nz-row [nzGutter]="16" *ngIf="error.length === 0 && captureStatus === 20">
            <div nz-col class="gutter-row" [nzSpan]="24">
               <button nz-button class="btn btn-blue Single-button mb-2 mr-2 mt-3 w-full" nzShape="round" (click)="capture()">
                  Snap Photo
               </button>
            </div>
         </div>
         <div nz-row [nzGutter]="16" *ngIf="error.length === 0 && captureStatus === 30">
            <div nz-col class="gutter-row" [nzSpan]="12">
               <button nz-button class="btn btn-red Single-button mb-2 mr-2 mt-3 w-full" nzShape="round" (click)="removeCurrent()">
                  Take Again
               </button>
            </div>
            <div nz-col class="gutter-row" [nzSpan]="12">
               <button nz-button class="btn btn-blue Single-button mb-2 mr-2 mt-3 w-full" nzShape="round" (click)="acceptImage()">
                  Done
               </button>
            </div>
         </div>
      </div>
   </ng-template>

   <ng-template #modalFooter>
      <button nz-button nzType="default" (click)="handleCloseModel()">Custom Callback</button>
      <button nz-button nzType="primary" (click)="handleCloseModel()" [nzLoading]="isConfirmLoading">Custom Submit</button>
   </ng-template>
</nz-modal>
