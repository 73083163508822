<div *ngIf="isVisible" class="flex justify-content-end">
   <div *ngIf="screenWidth > 450; else mobileNavigate">
      <nz-col>
         <button nzTooltipTitle="Help" nzTooltipPlacement="bottom" nz-tooltip  nz-button class="btn btn-ligher  ml-2" nzShape="circle" nz-dropdown [nzDropdownMenu]="guides" nzTrigger="click"  >
            <span nz-icon nzType="question-circle" nzTheme="outline"></span>
         </button>
         <nz-dropdown-menu #guides="nzDropdownMenu">
            <ul nz-menu nzSelectable>
               <li nz-menu-item (click)="navigateTo('consumer/user-manual/' + 0)">User Manuals</li>
               <li nz-menu-item (click)="navigateTo('consumer/user-manual/' + 1)">
                  Training Videos
               </li>
            </ul>
         </nz-dropdown-menu>
      </nz-col>
      <nz-col nzTooltipTitle="Home" nzTooltipPlacement="bottom" nz-tooltip>
         <button nz-button class="btn btn-ligher   ml-2" nzShape="circle" (click)="viewHome()">
            <span nz-icon nzType="home" nzTheme="outline"></span>
            
         </button>
         
      </nz-col>

      <nz-col>
         <button
            nz-button
            class="btn btn-ligher   ml-2"
            nz-dropdown
            [nzDropdownMenu]="menu"
            nzShape="circle"
            [nzPlacement]="'bottomCenter'"
            nzTrigger="click" nzTooltipTitle="All Modules" nzTooltipPlacement="bottom" nz-tooltip 
         >
            <span nz-icon nzType="setting" nzTheme="outline"></span>
         </button>
         <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
               <li nz-menu-item (click)="navigateTo('supplier-management/connections')" *ngIf="showCompanyProfile">Supplier Management</li>
               <li nz-menu-item *ngFor="let app of grantedApplications" (click)="navigateTo(app.application.portalUrl)">
                  {{ app.application.applicationName }}
               </li>
               <li nz-menu-item (click)="navigateTo('consumer/company-profile/' + userData.tenantId)" *ngIf="showCompanyProfile">Company Profile</li>
               <li nz-menu-item (click)="navigateTo('consumer/users')" *ngIf="hideConnectionUserMangeProfile">Manage Users</li>
               <li nz-menu-item (click)="navigateTo('consumer/sites')" *ngIf="showBaseData">Manage Sites</li>
               <li nz-menu-item (click)="navigateTo('consumer/buildings')" *ngIf="showBaseData">Manage Buildings</li>
               <li nz-menu-item (click)="navigateTo('consumer/assetcategories')" *ngIf="showBaseData">Manage Asset Categories</li>
               <li nz-menu-item (click)="navigateTo('consumer/assetsystems')" *ngIf="showBaseData">Manage Asset Systems</li>
            </ul>
         </nz-dropdown-menu>
      </nz-col>
   </div>
   <ng-template #mobileNavigate>
      <button nz-button class="btn btn-outline-blue  mx-2" nzShape="circle" nz-dropdown [nzDropdownMenu]="guidesMobile" nzTrigger="click">
         <span nz-icon nzType="caret-down" nzTheme="outline"></span>
      </button>
      <nz-dropdown-menu #guidesMobile="nzDropdownMenu">
         <ul nz-menu nzSelectable>
            <li nz-menu-item (click)="viewHome()"><span nz-icon nzType="home" nzTheme="outline"></span> Home</li>
            <li nz-submenu nzTitle="Modules" nzIcon="appstore">
               <ul>
                  <li nz-menu-item (click)="navigateTo('supplier-management/connections')" *ngIf="showCompanyProfile">Supplier Management</li>
                  <li nz-menu-item *ngFor="let app of grantedApplications" (click)="navigateTo(app.application.portalUrl)">
                     {{ app.application.applicationName }}
                  </li>
                  <li nz-menu-item (click)="navigateTo('consumer/company-profile/' + userData.tenantId)" *ngIf="showCompanyProfile">Company Profile</li>
                  <li nz-menu-item (click)="navigateTo('consumer/users')" *ngIf="hideConnectionUserMangeProfile">Manage Users</li>
                  <li nz-menu-item (click)="navigateTo('consumer/sites')" *ngIf="showBaseData">Manage Sites</li>
                  <li nz-menu-item (click)="navigateTo('consumer/buildings')" *ngIf="showBaseData">Manage Buildings</li>
                  <li nz-menu-item (click)="navigateTo('consumer/assetcategories')" *ngIf="showBaseData">Manage Asset Categories</li>
                  <li nz-menu-item (click)="navigateTo('consumer/assetsystems')" *ngIf="showBaseData">Manage Asset Systems</li>
               </ul>
            </li>
            <li nz-submenu nzTitle="Help" nzIcon="question-circle">
               <ul>
                  <li nz-menu-item (click)="navigateTo('consumer/user-manual/' + 0)">User Manuals</li>
                  <li nz-menu-item (click)="navigateTo('consumer/user-manual/' + 1)">Training Videos</li>
               </ul>
            </li>
         </ul>
      </nz-dropdown-menu>
   </ng-template>
</div>
