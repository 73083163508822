import { Component, AfterViewInit, OnDestroy, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { CameraStatus } from '../Enums';

@Component({
   selector: 'app-camera-snap',
   templateUrl: './camera-snap.component.html',
   styleUrls: ['./camera-snap.component.scss'],
})
export class CameraSnapComponent implements AfterViewInit, OnDestroy {
   @Output() pushImage: EventEmitter<any> = new EventEmitter<any>();
   cameraRatio: any = { width: 0, height: 0 };
   screenRatio: any = { width: 0, height: 0 };
   captureStatus: CameraStatus = CameraStatus.PreSnapStage;

   @ViewChild('video')
   public video: ElementRef;
   @ViewChild('canvas')
   public canvas: ElementRef;

   stream: any | null = null;
   captures: string | null = null;
   error: any[] = [];
   isOpenModel: boolean = false;

   constructor() {}

   async ngAfterViewInit(): Promise<void> {}

   async ngOnDestroy(): Promise<void> {
      await navigator.mediaDevices.getUserMedia({
         video: false,
      });
   }

   async setupDevices(): Promise<void> {
      if (navigator?.mediaDevices && navigator?.mediaDevices?.getUserMedia) {
         try {
            this.stream = await navigator.mediaDevices.getUserMedia({
               video: true,
            });

            if (this.stream) {
               this.video.nativeElement.srcObject = this.stream;
               this.video.nativeElement.play();
               this.video.nativeElement.onloadedmetadata = () => {
                  this.cameraRatio = { width: this.video.nativeElement.videoWidth, height: this.video.nativeElement.videoHeight };
               };
               this.error = [];
            } else {
               this.error.push('You have no output video device');
            }
         } catch (e) {
            this.error.push(e);
            this.stream = null;
         }
      }
   }

   capture(): void {
      this.captureStatus = CameraStatus.PostSnapStage;
      this.drawImageToCanvas(this.video.nativeElement);
      this.captures = this.canvas.nativeElement.toDataURL('image/png');

      if (this.stream !== null) {
         this.stream.getTracks().forEach((track) => track.stop());
         this.stream = null;
      }
   }

   async removeCurrent(): Promise<void> {
      this.captures = null;
      this.stream = await navigator.mediaDevices.getUserMedia({
         video: true,
      });
      this.video.nativeElement.srcObject = this.stream;
      this.video.nativeElement.play();
      this.video.nativeElement.onloadedmetadata = () => {
         this.cameraRatio = { width: this.video.nativeElement.videoWidth, height: this.video.nativeElement.videoHeight };
      };
      this.captureStatus = CameraStatus.ReadySnapStage;
   }

   drawImageToCanvas(image: any): void {
      this.canvas.nativeElement.getContext('2d').drawImage(image, 0, 0, this.cameraRatio.width, this.cameraRatio.height);
   }

   getRatioParam(): any {
      return this.cameraRatio;
   }

   async openModel(): Promise<void> {
      this.isOpenModel = true;
      this.captureStatus = 20;
      await this.setupDevices();
   }

   async handleCloseModel(): Promise<void> {
      this.isOpenModel = false;
      this.captureStatus = 10;
      this.captures = null;

      if (this.stream !== null) {
         this.stream.getTracks().forEach((track) => track.stop());
         this.stream = null;
      }
   }

   async acceptImage(): Promise<void> {
      this.pushImage.emit(this.captures);
      this.isOpenModel = false;
      this.captureStatus = 10;
      this.captures = null;
   }
}
