import { Directive, ElementRef, HostListener, Input, ComponentFactoryResolver, ViewContainerRef, OnInit } from '@angular/core';
import { ContextMenuComponent } from './context-menu/context-menu.component'

@Directive({
  selector: '[appContextMenu]'
})
export class ContextMenuDirective implements OnInit {
  @Input() data: any = {};
  @Input() contextMenu!: ContextMenuComponent;

  constructor(private elementRef: ElementRef, private viewContainerRef: ViewContainerRef, private resolver: ComponentFactoryResolver) {}

  ngOnInit(): void {
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event: MouseEvent) {
    event.preventDefault();
    if (this.contextMenu) {
      this.contextMenu.openMenu(event.clientX, event.clientY, this.data);
    }
  }
}
