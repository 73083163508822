import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { PageHeaderComponent } from './page-header/page-header.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { AppNgZorroAntdModule } from './nz/nz.module';
import { CommonNavigateComponent } from './common-navigate/common-navigate.component';
import { GeoMapComponent } from './geo-map/geo-map.component';
import { RouterModule, Routes } from '@angular/router';
import { ChangePasswordComponent } from './change-password/change-password/change-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GeoFencingComponent } from './geo-fencing/geo-fencing.component';
import { DoubleClickDirective } from './directives/double-click/double-click.directive';
import { ContextMenuDirective } from './directives/context-menu/context-menu.directive';
import { ContextMenuComponent } from './directives/context-menu/context-menu/context-menu.component';
import { CameraSnapComponent } from './camera-snap/camera-snap.component';

const routes: Routes = [
   // {
   //    path: '',
   //    component: SharedBaseLayoutComponent,
   //    children: [
   //       {
   //          path: 'usersetting/:id',
   //          component: ChangePasswordComponent,
   //       },
   //    ],
   // },
];

@NgModule({
   declarations: [
      PageHeaderComponent, 
      FileUploadComponent, 
      UserMenuComponent,
      CommonNavigateComponent, 
      GeoMapComponent, 
      ChangePasswordComponent, 
      GeoFencingComponent, 
      DoubleClickDirective, 
      ContextMenuDirective, 
      ContextMenuComponent, 
      CameraSnapComponent,
   ],
   imports: [
      AppNgZorroAntdModule, 
      CommonModule, 
      LeafletModule,
      FormsModule, 
      ReactiveFormsModule, 
      RouterModule.forChild(routes),
   ],
   exports: [
      PageHeaderComponent,
      UserMenuComponent, 
      FileUploadComponent, 
      CommonNavigateComponent, 
      GeoMapComponent, 
      GeoFencingComponent, 
      DoubleClickDirective, 
      ContextMenuDirective, 
      ContextMenuComponent,
      CameraSnapComponent,
   ],
})
export class SharedModule { }
